<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-row class="invoice-add">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="9" md="8">
          <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
              <b-card no-body class="invoice-preview-card">
                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">
                  <div class="
                      d-flex
                      justify-content-between
                      flex-md-row flex-column
                      invoice-spacing
                      mt-0
                    ">
                    <!-- Header: Left Content -->
                    <div>
                      <div class="logo-wrapper mb-0">
                        <h3 class="text-primary invoice-logo ml-0" style="font-size: 22px">
                          {{ invoiceData.company_name_en }}
                          <br />
                          {{ invoiceData.company_name_cn }}
                        </h3>
                      </div>
                      <b-card-text class="mb-0 logo-address">
                        {{ invoiceData.company_address_en }}
                      </b-card-text>
                      <b-card-text class="mb-0 logo-address">
                        {{ invoiceData.company_address_cn }}
                      </b-card-text>
                      <b-card-text class="mb-0 logo-address">
                        <span v-if="invoiceData.company_tel">Tel. 電話： {{ invoiceData.company_tel }}</span><span
                          class="ml-1" v-if="invoiceData.fax">Fax 圖文傳真：{{ invoiceData.fax }}</span><span class="ml-1"
                          v-if="invoiceData.company_email">
                          Email 電郵：{{ invoiceData.company_email }}</span>
                      </b-card-text>
                    </div>

                    <!-- Header: Right Content 
                                
                                -->
                  </div>
                  <b-row>
                    <!-- Header: Left Content -->
                    <b-col cols="12" sm="7">
                      <div class="client-information">
                        <div style="padding: 5px 15px">
                          <b-form-input v-model="invoiceData.client_contact_name" type="text" class="mb-0"
                            placeholder="發票收件人 " />
                          <b-form-input v-model="invoiceData.client_company_name_cn" type="text" class="mb-0"
                            placeholder="公司名稱中文" />
                          <b-form-input v-model="invoiceData.client_company_name_en" type="text" class="mb-0"
                            placeholder="公司名稱英文" />
                          <b-form-input v-model="invoiceData.client_address" type="text" class="mb-0"
                            placeholder="公司地址" />
                        </div>
                      </div>
                    </b-col>
                    <!-- Header: Right Content -->
                    <b-col class="invoice-number-date mt-md-0 mt-2" cols="12" sm="5">
                      <div class="mb-1">
                        <h4 class="invoice-title">發票</h4>
                      </div>
                      <div class="d-flex align-items-center mb-1">
                        <span class="title"> Inv No. 發票編號 ： </span>
                        {{ invoiceData.invoice_number }}
                      </div>
                      <div class="d-flex align-items-center mb-1">
                        <span class="title"> Ref. No. 客戶編號 ： </span>
                        {{ invoiceData.client && invoiceData.client.client_code }}
                      </div>
                      <validation-provider #default="validationContext" name="Invoice Date" rules="required">
                        <div class="d-flex align-items-center">
                          <span class="title">
                            Date <span class="title-right">日期 ：</span>
                          </span>
                          <b-form-datepicker id="invoice_date" :state="getValidationState(validationContext)"
                            placeholder="Choose a date" local="en" v-model="invoiceData.invoice_date"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            }" />
                        </div>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12" sm="12" class="mt-1 client_contact">
                      <b-row>
                        <b-col cols="12" sm="6">
                          <b-form-group label="Tel. 電話 ：" label-for="client_tel" label-cols-md="3">
                            <b-form-input v-model="invoiceData.client_phone" id="client_tel" placeholder="Tel. 電話 ：" />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6">
                          <b-form-group label="電郵： " label-for="client_email" label-cols-md="3">
                            <b-form-input v-model="invoiceData.client_email" id="client_email" placeholder="電郵： " />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-body>
                <!-- Spacer -->
                <hr />
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="invoice-items repeater-form" :style="{ height: trHeight }">
                    <b-row v-for="(item, index) in invoiceData.items" :key="index" ref="row" class="pb-2">
                      <!-- Item Form -->
                      <!-- ? This will be in loop => So consider below markup for single item -->
                      <b-col cols="12">
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <!-- Single Item Form Headers -->
                            <b-col cols="12" lg="6"> 產品/服務 </b-col>
                            <b-col cols="12" lg="2"> 單價</b-col>
                            <b-col cols="12" lg="2"> 數量 </b-col>
                            <b-col cols="12" lg="2"> 總價 </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>

                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <!-- Single Item Form Headers -->

                            <b-col cols="12" lg="6">
                              <label class="d-inline d-lg-none">產品/服務</label>
                              <v-select v-model="item.product_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="productOptions" label="name" :reduce="(option) => option.id"
                                :clearable="true" class="mb-2 item-selector-title" placeholder="選擇產品或服務"
                                @input="(val) => updateItemForm(index, val)">
                              </v-select>
                              <b-form-input v-model="item.name" type="text" class="mb-2" placeholder="產品或服務名稱" />
                              <b-form-textarea v-model="item.description" class="mb-2 mb-lg-0" placeholder="產品描述" />
                            </b-col>

                            <b-col cols="12" lg="2">
                              <label class="d-inline d-lg-none">單價</label>
                              <b-form-input v-model="item.amount" type="number" class="mb-2" />
                            </b-col>
                            <b-col cols="12" lg="2">
                              <label class="d-inline d-lg-none">數量</label>
                              <b-form-input v-model="item.quantity" type="number" class="mb-2" />
                            </b-col>
                            <b-col cols="12" lg="2">
                              <label class="d-inline d-lg-none">總價</label>
                              <b-form-input :value="item.amount * item.quantity" type="number" class="mb-2" disabled />
                            </b-col>
                          </b-row>
                          <div class="
                              d-flex
                              flex-column
                              justify-content-between
                              border-left
                              py-50
                              px-25
                            ">
                            <feather-icon size="16" icon="XIcon" class="cursor-pointer text-danger" @click="removeItem(index)" />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
                <!-- Items Section -->
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form" :style="{ height: trHeight }"></div>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary"
                    @click="addNewItemInItemForm">
                    新增產品或服務
                  </b-button>
                  <div style="text-align:right">
                    <h4>總計 Grand Total: <b>${{total}}</b></h4>
                  </div>
                </b-card-body>
                <hr />
                <!-- Invoice Remark -->
                <b-card-body class="invoice-padding pb-0 invoice-payment-method" >
                  <b-row>
                    <b-col cols="12">
                      <h4>發票備註</h4>
                      <v-select v-model="remark_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="invoiceRemarkOptions" label="name" :reduce="(option) => option.id"
                      :clearable="true" class="mb-2 item-selector-title" placeholder="選擇發票備註"
                      @input="(val) => updateRemark(val)">
                    </v-select>
                    </b-col>
                    <b-col cols="12">
                      <b-form-textarea v-model="invoiceData.remark" rows="10" placeholder="發票備註" />
                    </b-col>
                  </b-row>

                  <b-row class="mt-2 mb-2">
                    <b-col cols="12">
                      <h4>支付備註</h4>
                      <v-select v-model="payment_remark_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="paymentRemarkOptions" label="name" :reduce="(option) => option.id"
                      :clearable="true" class="mb-2 item-selector-title" placeholder="選擇支付備註"
                      @input="(val) => updatePaymentRemark(val)">
                    </v-select>
                    </b-col>
                    <b-col cols="12">
                      <b-form-textarea v-model="invoiceData.payment_terms" rows="10" placeholder="支付備註" />
                    </b-col>
                  </b-row>

                  <b-row class="mt-2 mb-2">
                    <b-col cols="12">
                      <h4>內部備註</h4>
                    </b-col>
                    <b-col cols="12">
                      <b-form-textarea v-model="invoiceData.internal_remark" rows="10" placeholder="內部備註" />
                    </b-col>
                  </b-row>

                </b-card-body>
          
          
              </b-card>
            </b-form>
          </b-overlay>
        </b-col>

        <!-- Right Col: Card -->
        <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
          <!-- Action Buttons -->
          <b-card>
            <!-- Button: Send Invoice
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            disabled
          >
            Send Invoice
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
          >
            Preview
          </b-button>
             -->
            <!-- Button: Print -->
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" block
              @click="handleSubmit(onSubmit)">
              儲存
            </b-button>

            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block
              v-if="invoiceData.client_id > 0" :to="{
                name: 'clients-detail',
                params: { id: invoiceData.client_id },
              }">
              客戶詳情
            </b-button>

            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block
              :to="{ name: 'invoices-list' }">
              發票列表
            </b-button>
          </b-card>

          <!-- Select Client -->
          <b-card>
            <div class="mt-2">
              <validation-provider #default="validationContext" name="客戶" rules="required">
                <b-form-group label="客戶" label-for="payment-client">
                  <b-form-input style="display: none" v-model="invoiceData.client_id"
                    :state="getValidationState(validationContext)" type="text" class="mb-0" placeholder="client_id" />
                  <v-select v-model="invoiceData.client_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clientOptions" label="client_code" :reduce="(option) => option.id" :clearable="false"
                    @input="val => updateInvoiceData(val)">
                    <template slot="option" slot-scope="option">
                      [{{ option.client_code }}] {{ option.company_name_cn }}
                    </template>
                    <template #selected-option="{ client_code, company_name_cn, }">
                      [{{ client_code }}] {{ company_name_cn }}
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <div class="mt-2">
                <validation-provider #default="validationContext" name="發票模版" rules="required">
                  <b-form-group label="發票模版" label-for="invoice_template_id">
                    <b-form-input style="display: none" v-model="invoiceData.invoice_template_id"
                      :state="getValidationState(validationContext)" type="text" class="mb-0" placeholder="選擇發票模版" />
                    <v-select v-model="invoiceData.invoice_template_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="invoiceTemplateOptions" label="name" :reduce="(option) => option.id" :clearable="false"
                   >
                    </v-select>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

              </div>
              <div class="mt-2">
                <b-form-group label="合約" label-for="contract_id">
                  <v-select searchable v-model="invoiceData.contract_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="contractOptions.filter(contract => contract.client_id == invoiceData.client_id)"
                    label="contract_code" :reduce="(option) => option.id" :clearable="true" />
                </b-form-group>
              </div>
              <div class="mt-2">
                <b-form-group label="項目" label-for="project_id">
                  <v-select v-model="invoiceData.project_id" searchable
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="projectOptions" label="project_id"
                    :reduce="(option) => option.id" :clearable="true" ref="selectedProject" />
                </b-form-group>
              </div>
              <div class="mt-2">
                <b-form-group label="銷售員" label-for="sale_id">
                  <v-select v-model="invoiceData.sale_id" searchable
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="userOptions" label="name"
                    :reduce="(option) => option.id" :clearable="true" ref="selectedUser" />
                </b-form-group>
              </div>
              <div class="mt-2">
                <b-form-group label="項目經理" label-for="sale_id">
                  <v-select v-model="invoiceData.project_manager_id" searchable
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="userOptions" label="name"
                    :reduce="(option) => option.id" :clearable="true" ref="selectedUser" />
                </b-form-group>
              </div>

              <!--    <div class="mt-2">
                <b-form-group label="Payment Terms" label-for="payment-method">
                  <v-select
                    searchable
                    v-model="invoiceData.bank_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paymentaccounts"
                    label="description"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  />
                </b-form-group>
              </div> -->

              <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->

              <!-- Payment Terms 
          <div class="d-flex justify-content-between align-items-center">
            <label for="patymentTerms">Payment Terms</label>
            <b-form-checkbox
              id="paymentTerms"
              :checked="true"
              switch
            />
          </div>


          <div class="d-flex justify-content-between align-items-center my-1">
            <label for="clientNotes">Client Notes</label>
            <b-form-checkbox
              id="clientNotes"
              :checked="true"
              switch
            />
          </div>


          <div class="d-flex justify-content-between align-items-center">
            <label for="paymentStub">Payment Stub</label>
            <b-form-checkbox
              id="paymentStub"
              switch
            />
          </div>
          -->
            </div>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import store from "@/store";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BOverlay,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "../invoiceStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, min } from "@validations";

export default {
  data(){
    return{
      remark_id: null,
      payment_remark_id: null
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    BOverlay,
    BFormInvalidFeedback,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  methods: {
    hide(){
      this.$router.replace('/invoices/list')
    },
    productSearch(options, search) {
      var returnOptions = options.filter(item => {
        return item.name.toLowerCase().includes(search.toLowerCase()) || item.model.toLowerCase().includes(search.toLowerCase()) || item.company_name_en.toLowerCase().includes(search.toLowerCase())
      })
      return returnOptions
    },
    clientSearch(options, search) {
      var returnOptions = options.filter(item => {
        return item.client_code.toLowerCase().includes(search.toLowerCase()) || item.company_name_cn.toLowerCase().includes(search.toLowerCase()) || item.company_name_en.toLowerCase().includes(search.toLowerCase())
      })
      return returnOptions
    },
    onSubmit() {
      console.log(this.invoiceData);
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("invoice/addInvoice", this.invoiceData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              if (this.invoiceData.id == 0) {
                this.hide();
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );
    },
    removeItem(index) {
      if(this.invoiceData.items.length == 1) {
        this.$swal({
          text: "至少需要一個產品或服務",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
        return;
      }


      this.invoiceData.items.splice(index, 1);
    },
  },
  computed: {
    total(){
      return this.invoiceData.items.reduce((acc, item) => acc + item.amount * item.quantity, 0) || "0"
    },
    client_id() {
      return this.invoiceData.client_id;
    },
    bank_id() {
      return this.invoiceData.bank_id;
    },
  },
  watch: {
    client_id() {
      /*  this.invoiceData.project_id = null;
       this.invoiceData.contract_id = null;
       var client = this.clients.filter(
         (item) => item.id == this.invoiceData.client_id
       );
       this.updateInvoiceData(client); */
    },
  },
  setup() {
    const INVOICE_STORE_MODULE_NAME = "invoice";
    const loading = ref(false);
    // Register module
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME))
      store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_STORE_MODULE_NAME);
    });

    const itemFormBlankItem = {
      product_id: null,
      name: "",
      description: "",
      amount: 0,
      quantity: 1
    };

    const invoiceData = ref({
      id: 0,
      client_id: null,
      invoice_number: null,
      contract_id: null,
      project_id: null,
      invoice_date: null,
      client_company_name_cn: "",
      client_company_name_en: "",
      client_contact_name: "",
      client_address: "",
      client_phone: "",
      client_email: "",
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      remark: "",
      payment_terms: "",
      invoice_template_id: null,
      sale_id: null,
      project_manager_id: null
    });

    if (router.currentRoute.name == "invoices-edit") {
      store
        .dispatch("invoice/fetchInvoice", { id: router.currentRoute.params.id })
        .then((response) => {
          invoiceData.value = response.data.invoice;
          invoiceData.value.bank_id = 1;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            invoiceData.value = undefined;
          }
        });
    }

    const clientOptions = ref([]);
    const projectOptions = ref([]);
    const contractOptions = ref([]);
    const invoiceRemarkOptions = ref([]);
    const paymentRemarkOptions = ref([]);
    const productOptions = ref([]);
    const userOptions = ref([])
    const invoiceTemplateOptions = ref([]);


    store
      .dispatch("app/fetchOptionList", { client: true, contract: true, project: true, invoice_template: true, invoice_remark: true, payment_remark: true, product: true, user: true, invoice_template: true})
      .then((response) => {
        clientOptions.value = response.data.clients;
        projectOptions.value = response.data.projects;
        contractOptions.value = response.data.contracts;
        invoiceRemarkOptions.value = response.data.invoice_remarks;
        paymentRemarkOptions.value = response.data.payment_remarks;
        productOptions.value = response.data.products;
        userOptions.value = response.data.users;
        invoiceTemplateOptions.value = response.data.invoice_templates;

        if (router.currentRoute.params.client_id) {
          invoiceData.value.client_id = router.currentRoute.params.client_id;
          updateInvoiceData(router.currentRoute.params.client_id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

    const updateItemForm = (index, val) => {
      if(!val) return;
      let product = productOptions.value.find((item) => item.id == val);
      if (product) {
        invoiceData.value.items[index].name = product.name;
        invoiceData.value.items[index].description = product.description;
        invoiceData.value.items[index].amount = product.price;
      }


      /*       var servicecode = servicecodes.value.filter((item) => item.id == val);
            servicecode = servicecode[0];
            const { cost, qty, description } = val;
            invoiceData.value.invoice_items[index].description =
              servicecode.description2; */
    };

    const updateRemark = (val) => {
      if(!val) return;
      let remark = invoiceRemarkOptions.value.find(item => item.id == val)
      if(remark)
      {
        invoiceData.value.remark = remark.remark
      }
    }
    
    const updatePaymentRemark = (val) => {
      if(!val) return;
      let remark = paymentRemarkOptions.value.find(item => item.id == val)
      if(remark)
      {
        invoiceData.value.payment_terms = remark.remark
      }
    }

    const updateInvoiceData = (val) => {
      store.dispatch(INVOICE_STORE_MODULE_NAME + '/fetchClient', val)
        .then((response) => {
          let client = response.data.client;
          invoiceData.value.client_company_name_cn = client.company_name_cn;
          invoiceData.value.client_company_name_en = client.company_name_en;
          invoiceData.value.client_address = client.br_address;
          invoiceData.value.contract_id = null;
          invoiceData.value.project_id = null;
          invoiceData.value.sale_id = client.sale_id;
          if (client.contacts && client.contacts.length > 0) {
            invoiceData.value.client_contact_name = client.contacts[0].contact_name;
            invoiceData.value.client_phone = client.contacts[0].contact_phone;
            invoiceData.value.client_email = client.contacts[0].contact_email;
          }

        })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      invoiceData,
      clientOptions,
      contractOptions,
      projectOptions,
      updateItemForm,
      itemFormBlankItem,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      updateInvoiceData,
      invoiceRemarkOptions,
      paymentRemarkOptions,
      invoiceTemplateOptions,
      userOptions,
      productOptions,
      updateRemark,
      updatePaymentRemark
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.logo-wrapper h3 span {
  color: black;
  font-size: 12px;
  font-weight: 100;
}

.logo-address {
  color: grey;
  font-weight: 100;
  font-size: 13px;
  font-style: italic;
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.invoice-add .invoice-preview-card .invoice-title {
  font-size: 1.6em;
  margin-right: 0;
}

.invoice-add .invoice-number-date .title {
  font-size: 0.9em;
  width: 150px;
  position: relative;
}

.invoice-add .invoice-number-date .title-right {
  position: absolute;
  right: 16px;
}

.client-information {
  width: 95%;
  height: 4cm;
  position: relative;
}

.client-information:before,
.client-information:after,
.client-information> :first-child:before,
.client-information> :first-child:after {
  position: absolute;
  width: 10px;
  height: 10px;
  border-color: #110f6a;
  /* or whatever colour */
  border-style: solid;
  /* or whatever style */
  content: " ";
}

.client-information:before {
  top: 0;
  left: 0;
  border-width: 1px 0 0 1px;
}

.client-information:after {
  top: 0;
  right: 0;
  border-width: 1px 1px 0 0;
}

.client-information> :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 1px 1px 0;
}

.client-information> :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 1px 1px;
}

.client-information .form-control {
  height: 35px;
}

.invoice_footer {
  height: 3.8cm;

  position: relative;
}

.invoice_footer h6 {
  font-weight: 100;
  font-size: 0.9em;
}

.invoice_signature {
  position: absolute;
  bottom: 0;
  width: 60%;
}

.invoice_signature hr {
  border-top: 1px solid grey;
}

.invoice-payment-detail {
  width: 7.5cm;
  height: 3.4cm;
  border: 1px solid grey;
  padding: 0.36cm 0.5cm;
  font-size: 0.8em;
}

.invoice-payment-detail tr {
  height: 0.6cm;
}

.invoice-payment-method {
  font-size: 0.85em;
  font-weight: 500;
  padding: 0 35px 0;
}

.invoice-items {
  // min-height:13.4cm;
  //border:1px solid #dde3ed;
}

.invoice-item-amount {
  position: relative;
  text-align: right;
}

.invoice-item-amount span {
  font-size: 0.9em;
  font-weight: 700;
}

// Fix dropdown-menu
.dropdown-menu {
  z-index: 10030 !important;
}

.required > label::after {
  content: " **";
  color: red;
}
</style>
